import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

const SEO = ({ lang, title, description, keywords, image, pathname, article, canonical }) => (
    <StaticQuery
        query={query}
        render={({
            site: {
                siteMetadata: {
                    defaultTitle,
                    titleTemplate,
                    defaultDescription,
                    siteUrl,
                    defaultImage,
                    defaultKeywords
                },
            },
        }) => {
            const seo = {
                title: title || defaultTitle,
                description: description || defaultDescription,
                keywords: keywords || defaultKeywords,
                image: `${siteUrl}${image || defaultImage}`,
                url: `${siteUrl}${pathname || "/"}`,
            }

            return (
                <>
                    <Helmet
                        htmlAttributes={{ lang }}
                        title={seo.title}
                        titleTemplate={titleTemplate}
                    >
                        {canonical &&
                            <link rel="canonical" href={`${canonical}`} />
                        }
                        <meta name="description" content={seo.description} />
                        <meta name="keywords" content={seo.keywords} />
                        <meta name="image" content={seo.image} />
                        {seo.url && (
                            <meta property="og:url" content={seo.url} />
                        )}
                        {(article ? true : null) && (
                            <meta property="og:type" content="article" />
                        )}
                        {seo.title && (
                            <meta property="og:title" content={seo.title} />
                        )}
                        {seo.description && (
                            <meta property="og:description" content={seo.description} />
                        )}
                        {seo.image && (
                            <meta property="og:image" content={seo.image} />
                        )}
                        <meta
                            name="twitter:card"
                            content="summary_large_image"
                        />
                        {seo.title && (
                            <meta name="twitter:title" content={seo.title} />
                        )}
                        {seo.description && (
                            <meta
                                name="twitter:description"
                                content={seo.description}
                            />
                        )}
                        {seo.image && (
                            <meta name="twitter:image" content={seo.image} />
                        )}
                        <script type="application/ld+json">{`
                            {
                                "@context": "https://schema.org",
                                "@type": "Organization",
                                "url": ${siteUrl},
                                "name": ${title},
                                "description": ${description},
                            }
                        `}</script>
                    </Helmet>
                </>
            )
        }}
    />
)
export default SEO

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
    image: PropTypes.string,
    pathname: PropTypes.string,
    article: PropTypes.bool,
}

SEO.defaultProps = {
    title: null,
    description: null,
    keywords: null,
    image: null,
    pathname: null,
    article: false,
}

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                defaultKeywords: keywords
                siteUrl: url
                defaultImage: image
            }
        }
    }
`

/*

- DELETE -

import React from "react";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import {useStaticQuery, graphql} from "gatsby";
//import { FormattedMessage, FormattedHTMLMessage, useIntl } from "gatsby-plugin-intl";

function SEO({ description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );
const metaDescription = description || site.siteMetadata.description;
    //const intl = useIntl();
    //intl.formatMessage({ id: "seo.home.title" }
        return (
    <Helmet
        htmlAttributes={{
            lang,
        }}
        title={title}
        titleTemplate={`${site.siteMetadata.title} | %s`}
        meta={[
            {
                name: `description`,
                content: metaDescription,
            },
            {
                property: `og:title`,
                content: title,
            },
            {
                property: `og:description`,
                content: metaDescription,
            },
            {
                property: `og:type`,
                content: `website`,
            },
            {
                name: `twitter:card`,
                content: `summary`,
            },
            {
                name: `twitter:creator`,
                content: site.siteMetadata.author,
            },
            {
                name: `twitter:title`,
                content: title,
            },
            {
                name: `twitter:description`,
                content: metaDescription,
            },
        ]
        .concat(
        keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
            }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `sv`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO;

/*
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta name="author" content="82 Consulting, Axel Roussille Åberg" />
<meta name="description" content="" />
<meta name="keywords" content="" />
<script type="application/ld+json">{`
{
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://82con.com",
    "description": "82 Consulting provides a wide range of services in graphics design and IT.",
    "name": "82 Consulting | A service minded service",
    "telephone": "+46-762772547",
}
`}</script>
*/
